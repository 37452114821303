<!--
 * @Descripttion: 
 * @Author: wuxinxin
 * @Date: 2022-03-19 18:59:32
 * @LastEditors: zhangguoliang
 * @LastEditTime: 2022-10-24 15:25:30
-->
<template>
  <div class="wrap">
    <div class="content-wrap">
      <img
        class="wrap-background"
        src="https://hzjt-ui-publiclib.oss-cn-beijing.aliyuncs.com/portal/YHQh5_01.png?v=1"
      />
    </div>
    <div class="filed">
      <div class="contacts">
        <div class="info">
          <van-field
            :class="{ 'field-disabled': disabled }"
            v-model="mobile"
            placeholder="请输入手机号"
            type="tel"
            maxlength="11"
            :disabled="disabled"
            :error="false"
            :rules="[{ required: true, message: '' }]"
          />
          <div class="codeMsg">
            <van-field
              :class="{ 'field-disabled': disabled }"
              v-model="mobileVerifyCode"
              :disabled="disabled"
              placeholder="请输入验证码"
              maxlength="6"
              type="tel"
            />
            <button
              class="code"
              :class="{ 'code-disabled': codeDisabled }"
              @click="start"
              type="text"
              :disabled="codeDisabled"
            >
              {{ codeMsg }}
            </button>
          </div>
          <!-- <div class="giftTitle" ></div> -->
          <!-- <div class="image-warper" >
            <div
              class="image-box"
              :class="activeIndex === index ? 'active' : ''"
              v-for="index in 3"
              :key="index"
              @click="select(index)"
              :disabled="disabled"
              :style="`background-image:url(https://hzjt-ui-publiclib.oss-cn-beijing.aliyuncs.com/YYFUPT/LI_0${index}.png)`"
            >
              <img
                v-show="activeIndex === index"
                class="gift-img"
                src="@/assets/img/select.png"
                alt=""
              />
            </div>
          </div>
          <van-field
            :class="{ 'field-disabled': disabled }"
            v-model="contacts"
            v-show="activeIndex !== 0 && !disabled"
            placeholder="请输入联系人"
          />
          <div class="codeMsg" v-show="activeIndex !== 0 && !disabled">
            <van-field
              :class="{ 'field-disabled': disabled }"
              v-model="provinces"
              @click="actionShows"
              placeholder="请选择省市区"
              readOnly
            />
          </div>
          <van-field
            :class="{ 'field-disabled': disabled }"
            v-model="address"
            v-show="activeIndex !== 0 && !disabled"
            placeholder="请输入详细地址"
            type="text"
          />
          <van-actionSheet v-model="actionShow">
            <van-area
              title="请选择省市区"
              :area-list="areaList"
              @confirm="confirm"
              @cancel="cancel"
            />
          </van-actionSheet> -->
        </div>
        <van-button
          type="info"
          native-type="submit"
          class="apply-submit"
          :disabled="disabled"
          :loading="loading"
          @click="onSubmit"
          >点 击 领 取
          <img class="button-icon" src="@/assets/img/button-icon.png" />
        </van-button>
      </div>
    </div>
    <div class="content-wrap">
      <img
        class="wrap-background footer-wrap"
        src="https://hzjt-ui-publiclib.oss-cn-beijing.aliyuncs.com/portal/YHQh5_02.png?v=1"
      />
    </div>
    <van-dialog v-model="show" title="温馨提示" :showConfirmButton="false">
      <div class="dialog-info">
        <div class="dialog-text">
          {{ dialogText }}
          <div v-show="urlShow">
            网址:
            <span class="url">https://www.yunsuanfang.com/ </span>
          </div>
        </div>
        <van-button
          native-type="submit"
          @click="copyToClipboard"
          class="dialog-close"
          >{{ buttonText }}
        </van-button>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
import { Notify } from 'vant';
import 'vant/lib/button/style';
import * as Api from '@/api/tryOut.ts';
import { areaList } from '@vant/area-data';
export default Vue.extend({
  data() {
    return {
      show: false,
      mobile: '',
      provinces: '',
      address: '',
      contacts: '',
      mobileVerifyCode: '',
      codeDisabled: true,
      codeMsg: '获取验证码 ',
      countdown: 60,
      timer: null,
      dialogText: '',
      urlShow: false,
      buttonText: '',
      disabled: false,
      loading: false,
      actionShow: false,
      activeIndex: 1,
      gift: false,
      provinceCode: '',
      cityCode: '',
      areaCode: '',
      province: '',
      city: '',
      area: '',
      areaList: {
        province_list: {},
        city_list: {},
        county_list: {},
      },
    };
  },
  mounted() {
    this.getToken();
  },
  created() {
    document.title = '领取优惠券';
    this.areaList = areaList;
  },
  //监听
  watch: {
    mobile: {
      handler(newName) {
        var mobile = /^[1][3,4,5,7,8,9][0-9]{9}$/.test(newName);
        if (mobile === false && mobile !== '') {
          this.codeDisabled = true;
        } else {
          this.codeDisabled = false;
        }
      },
    },
  },
  methods: {
    /**
     * @description: 获取token
     * @param {*}
     * @return {number}
     */
    getToken() {
      Api.submitToken().then((res) => {
        if (res) {
          window.localStorage.setItem('submitToken', res.submitToken);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    /**
     * @description: 省市区选择确认
     * @param {*}
     * @return {number}
     */
    confirm(item) {
      (this.provinceCode = item[0].code),
        (this.cityCode = item[1].code),
        (this.areaCode = item[2].code),
        (this.province = item[0].name),
        (this.city = item[1].name),
        (this.area = item[2].name),
        (this.actionShow = false);
      this.provinces = item[0].name + item[1].name + item[2].name;
    },
    /**
     * @description: 省市区选择取消
     * @param {*}
     * @return {number}
     */
    cancel() {
      this.actionShow = false;
    },
    /**
     * @description: 省市区选择面板打开
     * @param {*}
     * @return {number}
     */
    actionShows() {
      document.activeElement.blur();
      this.actionShow = true;
    },
    /**
     * @description: 礼品选择
     * @param {*}
     * @return {number}
     */
    select(index) {
      if (this.activeIndex === index) {
        this.activeIndex = 0;
      } else {
        this.activeIndex = index;
      }
    },
    giftSelect() {
      if (this.activeIndex === 1) {
        return '米';
      }
      if (this.activeIndex === 2) {
        return '面';
      }
      if (this.activeIndex === 3) {
        return '油';
      }
    },
    /**
     * @description: 获取h5执行时所在环境 0 web浏览器 5 抖音 4 微信
     * @param {*}
     * @return {number}
     */
    channel() {
      const ua = window.navigator.userAgent.toLowerCase();
      if (/MicroMessenger/i.test(ua)) {
        return 5;
      }
      if (/aweme/i.test(ua)) {
        return 4;
      }
      return 3;
    },
    /**
     * @description: 表单验证提交
     * @param {*}
     * @return {number}
     */
    onSubmit() {
      if (!this.mobile || !/^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.mobile)) {
        Notify('请输入正确手机号');
        return;
      }
      if (this.mobileVerifyCode === '') {
        Notify('请输入验证码');
        return;
      }
      // if (this.activeIndex !== 0) {
      //   if (
      //     !this.contacts ||
      //     !/^[a-zA-Z\u4e00-\u9fa5]{2,10}$/.test(this.contacts)
      //   ) {
      //     Notify(' 联系人由2-10位字母或汉字组成');
      //     return;
      //   } else {
      //     if (this.provinces === '') {
      //       Notify('请选择省市区');
      //     } else {
      //       if (this.address === '') {
      //         Notify('请输入详细地址');
      //       } else {
      //         this.gift = true;
      //       }
      //     }
      //   }
      // }
      // if (this.activeIndex === 0 || this.gift) {
      const param = {
        mobile: this.mobile,
        smsVerifyCode: this.mobileVerifyCode,
        source: this.channel(),
        name: this.contacts,
        receiveMobile: this.mobile,
        address: this.address,
        provinceCode: this.provinceCode || '',
        cityCode: this.cityCode || '',
        areaCode: this.areaCode || '',
        province: this.province || '',
        city: this.city || '',
        area: this.area || '',
        // giftKey: this.activeIndex || '',
        // giftValue: this.giftSelect(),
      };
      this.loading = true;
      Api.coupons_receive(param)
        .then((res) => {
          if (res) {
            this.loading = false;
            const type = res.code;
            if (type === '1') {
              Notify('验证码错误');
            } else {
              this.show = true;
              this.dialogText = res.desc;
              if (type === '0') {
                this.buttonText = '复制网址';
                this.urlShow = true;
              } else {
                this.urlShow = false;
                if (type === '2') {
                  this.buttonText = '知道了';
                } else {
                  if (type === '4') {
                    this.buttonText = '知道了';
                    this.disabled = true;
                  } else {
                    this.buttonText = '重新输入';
                  }
                }
              }
            }
          } else {
            this.getToken();
            this.$message.error(res.message);
            this.loading = false;
          }
        })
        .finally(() => {
          this.getToken();
          this.loading = false;
        });
      // }
    },
    /**
     * @description: 表单清空
     * @param {*}
     * @return {number}
     */
    reset() {
      this.mobile = '';
      this.mobileVerifyCode = '';
      this.provinces = '';
      this.address = '';
      this.contacts = '';
      this.activeIndex = 1;
      clearInterval(this.timer);
      this.codeMsg = '获取验证码';
      this.countdown = 60;
      this.timer = null;
      this.codeDisabled = false;
    },
    /**
     * @description: 弹框关闭，表单清空
     * @param {*}
     * @return {number}
     */
    dialogClose() {
      this.show = false;
      this.reset();
    },
    /**
     * @description: 获取验证码
     * @param {*}
     * @return {number}
     */
    start() {
      if (this.mobile !== '') {
        Api.receiveCouponVerifyCode(this.mobile).then((res) => {
          if (res) {
            this.codeDisabled = true;
            if (!this.timer) {
              this.timer = setInterval(() => {
                if (this.countdown > 0 && this.countdown <= 60) {
                  this.countdown--;
                  if (this.countdown !== 0) {
                    this.codeDisabled = true;
                    this.codeMsg = '重新发送(' + this.countdown + ')';
                  } else {
                    clearInterval(this.timer);
                    this.codeMsg = '获取验证码';
                    this.countdown = 60;
                    this.timer = null;
                    this.codeDisabled = false;
                  }
                }
              }, 1000);
            }
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        this.codeDisabled = false;
      }
    },
    /**
     * @description: 复制链接
     * @param {*}
     * @return {number}
     */
    copyToClipboard() {
      if (this.urlShow) {
        const copyContent = `https://www.yunsuanfang.com/`;
        // navigator clipboard 需要https等安全上下文
        if (navigator.clipboard && window.isSecureContext) {
          navigator.clipboard.writeText(copyContent).then(
            () => {
              this.$message.success('复制成功');
              this.show = false;
              this.reset();
            },
            () => {
              this.$message.success('您的浏览器暂不支持此功能');
              this.show = false;
              this.reset();
            }
          );
        } else {
          // 创建text area
          let textArea = document.createElement('textarea');
          textArea.value = copyContent; // 使text area不在viewport，同时设置不可见
          textArea.style.position = 'absolute';
          textArea.style.opacity = 0;
          textArea.style.left = '-999999px';
          textArea.style.top = '-999999px';
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          return new Promise((res, rej) => {
            // 执行复制命令并移除文本框
            document.execCommand('copy') ? res : rej;
            textArea.remove();
            this.$message.success('复制成功!');
            this.show = false;
            this.reset();
          });
        }
      }
      this.dialogClose();
    },
  },
});
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  .content-wrap {
    overflow-y: auto;
    position: relative;
    .wrap-background {
      width: 100%;
    }
    .footer-wrap {
      margin-top: -6px;
    }
  }
  .filed {
    background: linear-gradient(180deg, #182eff 0%, #0a21ff 100%);
    padding: 0 0.349rem 0.6rem;
    margin-bottom: -3px;
    .contacts {
      background: linear-gradient(180deg, #f64a00 0%, #ee2a00 100%);
      border-radius: 0px 0px 55px 55px;
      padding: 20px 30px 40px;
      margin-top: -2px;
      .info {
        .codeMsg {
          padding: 18px 0px;
          display: flex;
          flex-direction: row;
          position: relative;
          .code {
            position: absolute;
            right: 2%;
            margin-top: 5px;
            white-space: nowrap;
            padding: 3px 10px;
            font-size: 15px;
            font-weight: 600;
            color: #f23a00;
            background: #ffffff;
            border: #ffffff;
            border-radius: 20px;
          }
          .code-disabled {
            color: #c8cddb;
          }
        }
        .giftTitle {
          margin: 0 auto;
          height: 25px;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url(https://hzjt-ui-publiclib.oss-cn-beijing.aliyuncs.com/YYFUPT/WENAN.jpg);
        }
        .image-warper {
          display: flex;
          justify-content: space-between;
          padding: 18px 0px;
          .image-box {
            position: relative;
            min-width: 31%;
            min-height: 74px;
            background-size: cover;
            background-repeat: no-repeat;
            overflow: hidden;
            .gift-img {
              position: absolute;
              display: block;
              right: 0px;
            }
          }
          .active {
            border: 3px solid #f6d177;
            border-radius: 18px;
          }
        }
      }
      .apply-submit {
        height: 65px;
        width: 100%;
        margin-top: 20px;
        color: #ef2b31;
        background: linear-gradient(180deg, #ffefbe 0%, #f8c136 100%);
        border: 1px solid #f8c136;
        border-radius: 35px;
        font-size: 26px;
        font-weight: 600;
        .button-icon {
          transform: scale(0.7);
        }
      }
    }
  }
}
.dialog-info {
  text-align: center;
  padding: 1px 5px 15px;
  .dialog-text {
    padding: 10px 15px;
    font-size: 16px;
    color: #333333;
    border-bottom: 1px solid #979797;
  }
  .url {
    color: #5457da;
  }
  .dialog-close {
    margin-top: 15px;
    padding: 10px 45px;
    border-radius: 30px;
    background: #1016ff;
    color: #ffffff;
  }
}
</style>
<style>
.van-field__control {
  font-size: 15px;
  color: #1d1d1d;
}
.van-cell {
  line-height: 35px;
  padding: 2px 25px;
  border-radius: 25px;
}
.van-dialog__header {
  font-size: 20px;
  color: #333333;
  font-weight: 600;
}
.ant-message {
  z-index: 3000 !important;
}
</style>
